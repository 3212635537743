import EaseeMap from "./map";
import { parseOrderOptions } from "./_block-order-guide"

var map, location, filter, filteredAccounts, filteredPartnersOnly;

var distanceLimit = 50; // km

const $orderbyDistance = $(".retailers.mapListViewOnly").length > 0 ? true : false;
const $filterInMap = $(".retailers.filter-in-map").length > 0 ? true : false;

var $retailersList = $('.retailers .list .accounts');
var $retailerBlock = $('.retailers.wholesalers');
var $retailersListPartners = $('.order-online .accounts');
var $filterCheckboxes = $('.retailers .filter-options').find('input[type="radio"]');
var $retailerSearchInput = $('.retailers .search-retailers').find('input');
var $citySearchInput = $('.retailers .search-city').find('input');
var $noResults = $('.retailers .no-results');

var allRetailers = window.easeeRetailers || [];
if($retailerBlock.length > 0) {
    var allRetailers = allRetailers.filter(e => e.type === "Wholesaler")
}

var onlyPartners = window.onlyPartners || [];
var homePartners = window.homePartners || [];
var homeInstallationPartners = window.homeInstallationPartners || [];
var chargePartners = window.chargePartners || [];
var accessoryPartners = window.accessoryPartners || [];
var nationalHomeInstallationPartners = window.nationalHomeInstallationPartners || [];
var nationalChargePartners = window.nationalChargePartners || [];

if(onlyPartners) {
    var stringifiedPartners = JSON.stringify(onlyPartners);
    allRetailers.map(el => { stringifiedPartners.indexOf(el.accountNumber) !== -1 ? el.type = "Partner" : ""})
}

var $combinedHeightTop = $(".retailers .map-content .map").length > 0 ? $(".retailers .map-content .map").offset().top : "";

// Change map from sticky to relative
$( document ).ready(function() {
    var $mapContainer = $(".retailers .map-content");

    if($(".retailers .map-content .map").length === 0) {
        jQuery.fn.shuffle = function () {
            var j;
            for (var i = 0; i < this.length; i++) {
                j = Math.floor(Math.random() * this.length);
                $(this[i]).before($(this[j]));
            }
            return this;
        };

        $('.retailers .list .accounts .account').shuffle();
    }

    if($mapContainer.length > 0) {
        var $retailerMap = $(".retailers .map-content .map");
        if($retailerMap.length > 0) {
            var $footerHeight = $("footer").outerHeight();
            var windowHeight = window.innerHeight;
            var footerOffsetTop = document.querySelector("footer").offsetTop - windowHeight;
            var $combinedHeightContent = $(document).outerHeight();

            var i = setInterval(function () {
                if (window.innerWidth >= 1000) {
                    if ($('.retailers .map-content .map >div').length) {
                        clearInterval(i);

                        if($(document).scrollTop() > footerOffsetTop) {
                            $retailerMap.css({
                                "position": "absolute",
                                "bottom": "0",
                                "top": "auto"
                            });
                        } else if(($(document).scrollTop() > $combinedHeightTop) && !($(document).scrollTop() > $combinedHeightContent - $footerHeight)) {
                            $retailerMap.css({
                                "position": "fixed",
                                "top": "0",
                                "bottom": "auto"
                            });
                        } else if($(document).scrollTop() < $combinedHeightTop) {
                            $retailerMap.css({
                                "position": "absolute",
                                "top": "0",
                                "bottom": "auto"
                            });
                        }
                    }
                } else {
                    clearInterval(i);
                }
            }, 100);

            $combinedHeightTop = $(".retailers .map-content .map").offset().top;
            
            $(window).scroll(function (e) {
                /* console.log($combinedHeightTop) */
                if (window.innerWidth >= 1000) {
                    var $retailerMap = $(".retailers .map-content .map");
                    var $footerHeight = $("footer").outerHeight();
                    var windowHeight = window.innerHeight;
                    var footerOffsetTop = document.querySelector("footer").offsetTop - windowHeight;
                    var $combinedHeightContent = $(document).outerHeight();
                    
                    if($retailerMap.length > 0) {
                        if($(document).scrollTop() > footerOffsetTop) {
                            if(orderPage) {
                                $retailerMap.css({
                                    "position": "absolute",
                                    "bottom": "5px",
                                    "top": "auto"
                                });
                            } else {
                                $retailerMap.css({
                                    "position": "absolute",
                                    "bottom": "0",
                                    "top": "auto"
                                });
                            }
                            /* console.log("first") */
                        } else if(($(document).scrollTop() > $combinedHeightTop) && !($(document).scrollTop() > $combinedHeightContent - $footerHeight)) {
                            if(orderPage) {
                                $retailerMap.css({
                                    "position": "fixed",
                                    "top": window.scrollY - 80,
                                    "bottom": "auto",
                                });
                            } else {
                                $retailerMap.css({
                                    "position": "fixed",
                                    "top": "0",
                                    "bottom": "auto",
                                });
                            }
                            /* console.log("middle, fixed") */
                        } else if($(document).scrollTop() < $combinedHeightTop) {
                            $retailerMap.css({
                                "position": "absolute",
                                "top": "0",
                                "bottom": "auto"
                            });
                            /* console.log("last last") */
                        }
                    }
                }
            })
            
        }
    }
});
const orderPage = $(".order-guide").length > 0 ? true : false;

// Style adjusts on resize
if($(".retailers .map-content .map").length > 0) {
    $(window).resize(function(e) {
        const $map = $(".retailers .map-content .map")

        if(window.innerWidth < 1000) {
            if(orderPage) {
                if(!$map.hasClass("open")) {
                    $map.css({
                        "position" : "fixed",
                        "top" : "85vh",
                    });
                }
                if($(".order-near > a").hasClass("active")) {
                    setTimeout(() => {
                        const $listTopOffset = $(".retailers .list").offset().top;
                        const $listHeight = $(".retailers .list").outerHeight();
                        if($listTopOffset !== 0) {
                            $(".order-guide .order-wrapper").css("min-height", ($listTopOffset + $listHeight))
                        }
                    }, 400);
                }
            } else {
                $map.css({
                    "position" : "fixed",
                    "top" : "165px",
                    "height": window.innerHeight - 165
                });
            }
        } else {
            if($map.hasClass("open")) {
                const $button = $(".retailers .map-close");
                const $input = $(".retailers .search-inputs .search-city");
                const $map = $(".retailers .map-content .map");
                const $background = $(".retailers .white-background");
                $background.css({"left": '0'});
                $map.css({"left": '0'});
                $input.css({"left": '0'});
                $button.css({"left": '0'});
                $("body").css({
                    "overflow" : "auto",
                });
                $("header").removeClass("show");
            }

            $map.css({
                "left" : "unset",
                "height": "100vh"
            });
            setTimeout(() => {
                const $listTopOffset = $(".retailers .list").offset().top;
                const $listHeight = $(".retailers .list").outerHeight();
                if(orderPage) {
                    $(".order-guide .order-wrapper").css("min-height", ($listTopOffset + $listHeight) - 85)
                } else {
                    $(".order-guide .order-wrapper").css("min-height", ($listTopOffset + $listHeight) - 80)
                }
            }, 400);

            setTimeout(() => { $combinedHeightTop = $(".retailers .map-content").offset().top }, 1000);
            setTimeout(() => {
                if(orderPage) {
                    $combinedHeightTop = $(".retailers .map-content").offset().top

                    var $retailerMap = $(".retailers .map-content .map");
                    var $footerHeight = $("footer").outerHeight();
                    var windowHeight = window.innerHeight;
                    var footerOffsetTop = document.querySelector("footer").offsetTop - windowHeight;
                    var $combinedHeightContent = $(document).outerHeight();
                    /* console.log($combinedHeightTop) */
                    if($(document).scrollTop() > footerOffsetTop) {
                        $retailerMap.css({
                            "position": "absolute",
                            "bottom": "5px",
                            "top": "auto"
                        });
                    } else if(($(document).scrollTop() > $combinedHeightTop) && !($(document).scrollTop() > $combinedHeightContent - $footerHeight)) {
                        $retailerMap.css({
                            "position": "fixed",
                            "top": window.scrollY - 80,
                            "bottom": "auto",
                        });
                    } else if($(document).scrollTop() < $combinedHeightTop) {
                        $retailerMap.css({
                            "position": "absolute",
                            "top": "0",
                            "bottom": "auto"
                        });
                    }
                }
            }, 700);
        }

        adjustPartnerStyles();
    });
} else {
    $(window).resize(function(e) {
        adjustPartnerInListStyles();
    });
}

// Accordion open/close sections
$(".order-online .accounts").hide();
$(".retailers .order-online > a").click(function(e) {
    /* console.log($combinedHeightTop) */
    e.preventDefault();

    $(this).toggleClass("active");
    if($(this).hasClass("active")) {
        $(".order-online .accounts").slideDown();
    } else {
        $(".order-online .accounts").slideUp();
    }
    setTimeout(() => { $combinedHeightTop = $(".retailers .map-content").offset().top }, 500);
});
$(".retailers .order-near > a").click(function(e) {
    e.preventDefault();
    /* console.log($combinedHeightTop) */
    $(this).toggleClass("active");
    if($(this).hasClass("active")) {
        $(".order-near .map-search-content").slideDown();
        $combinedHeightTop = $(".retailers .map-content").offset().top;
        const $retailerMap = $(".retailers .map-content .map");
        if(window.innerWidth < 1000) {
            if(orderPage) {
                $retailerMap.css({
                    "position": "fixed",
                    "top": "85vh",
                    "bottom": "auto"
                });
            } else {
                $retailerMap.css({
                    "position": "fixed",
                    "top": "25vh",
                    "bottom": "auto"
                });
            }
        } else {
            $retailerMap.css({
                "position": "absolute",
                "top": "0",
                "bottom": "auto"
            });
        }
    } else {
        $(".order-near .map-search-content").slideUp();
    }
    setTimeout(() => { $combinedHeightTop = $(".retailers .map-content").offset().top }, 700);
});

// Overlay map
$(".retailers .map-close .map-close-button").click(function(e) {
    e.preventDefault();
    $("header").fadeIn();
    const $input = $(".retailers .search-inputs .search-city");
    const $button = $(".retailers .map-close");
    const $map = $(".retailers .map-content .map");
    const $background = $(".retailers .white-background");

    $button.find("a").addClass("active");
    $background.animate({"left": '0'}, 300);
    $map.animate({"left": '0'}, 300);
    $map.removeClass("open")
    $input.animate({"left": '0'}, 300);
    $button.animate({"left": '0'}, 300);
    $("body").css({
        "overflow" : "auto",
    });
    setTimeout(() => { $("header").removeClass("show") }, 100);
})
$(".retailers .tab-map-list .premium-button").click(function(e) {
    e.preventDefault();
})
$(".retailers .tab-map-list .map-tab").click(function(e) {
    e.preventDefault();
    $("header").fadeOut();
    const $input = $(".retailers .search-inputs .search-city");
    const $button = $(".retailers .map-close");
    const $map = $(".retailers .map-content .map");
    const $background = $(".retailers .white-background");

    $button.find("a").removeClass("active");
    $background.animate({"left": '-100vw'}, 300);
    $map.animate({"left": '-100vw'}, 300);
    $map.css({
        "top": "165px",
        "position": "fixed",
        "height": window.innerHeight - 165
    });

    $map.addClass("open");
    $input.animate({"left": '-100vw'}, 300);
    $button.animate({"left": '-100vw'}, 300);
    $("body").css({
        "overflow" : "hidden",
    });
    setTimeout(() => { $("header").removeClass("show") }, 100);
});

(function setupRetailersBlock() {
    if ($retailersList.length === 0) {
        return;
    }

    setupFiltering();

    var sortedAccounts = sortAccounts(allRetailers);

    var sorted = $retailersList.find('.account').sort(function(a, b) {
        var aId = a.getAttribute('data-account-number');
        var bId = b.getAttribute('data-account-number');

        return sortedAccounts.indexOf(aId) < sortedAccounts.indexOf(bId) ? -1 : 1;
    });

    $retailersList.html(sorted);
    setupAccountClickListeners();
    updateFilter();
    $retailersList.addClass('show');

    setTimeout(function() {
        sortByLocation();
    }, 200);
})();

function isDevEnvironment() {
    return window.location.hostname === 'easee.test';
}

function sortByLocation() {
    if (!navigator.geolocation) {
        console.error('Geolocation not supported.');
        return;
    }

    function geoError(error) {
        console.error('Error loading geolocation', error)
    }

    /*if (isDevEnvironment()) {
        geoSuccess({coords: {latitude: 58.7343343, longitude: 5.6483699}});
    } else {*/
    navigator.geolocation.getCurrentPosition(geoSuccess, geoError);
    //}
}

function geoSuccess(position) {
    if (!position || !position.coords) {
        return;
    }

    var lat = position.coords.latitude;
    var lng = position.coords.longitude;

    location = { lat: lat, lng: lng };

    if (map && map.map) {
        map.map.setCenter(location);
        map.map.setZoom(10);
    }

    var sortedAccounts = sortAccounts(allRetailers);

    var sorted = $retailersList.find('.account').sort(function(a, b) {
        return sortedAccounts.indexOf(a.getAttribute('data-account-number')) - sortedAccounts.indexOf(b.getAttribute('data-account-number'));
    }).each(function(index, el) {
        var lat1 = parseFloat(el.getAttribute('data-lat')) || 0;
        var lng1 = parseFloat(el.getAttribute('data-lng')) || 0;
        var dist = Math.round(distance(lat, lng, lat1, lng1, 'K'));

        dist = dist === 0 ? '< 1 km' : '' + dist + ' km';

        var destination = el.getAttribute('data-address');
        if (destination) {
            dist += ' – <a href="https://www.google.com/maps/dir/?api=1&destination=' + destination + '" target="_blank">' + (easeeTrans.directions || 'Directions') + '</a>';
        }

        $(el).find('.distance').addClass('show').html(dist);
    });

    $retailersList.html(sorted);
    setupAccountClickListeners();

    $retailersList.addClass('show');
    updateFilter();
}

export function setupFiltering() {
    var type = 'home-installation';
    if($(".retailers .filter-options li.home_installation").hasClass("inactive")) {
        $(".retailers .filter-options li").each((i, el) => {
            if(!el.classList.contains("inactive")) {
                type = el.children[1].value;
            }
        })
    } else {
        type = 'home-installation';
    }
    filter = {search: '', type: type, searchCode: ''};

    var options = parseOrderOptions(window.location.search);
    if (options.product === 'home') {
        if (options.installation === '0') {
            filter.type = 'home';
        } else {
            filter.type = 'home-installation';
        }
    } else if (options.product === 'charge') {
        filter.type = 'charge';
    } else if (options.product === 'accessories') {
        filter.type = 'accessories';
    } else if(options.product === "") {
        if(options.installation === '1') {
            filter.type = 'home-installation';
        } else if(options.installation === '0') {
            filter.type = 'home';
        } else {

        }
    }

    switch (filter.type) {
        case 'home':
            $('#easee-home')[0].checked = true;
            $('#easee-home-installation')[0].checked = false;
            $('#easee-charge')[0].checked = false;
            $('#accessories')[0].checked = false;
            break;
        case 'accessories':
            $('#easee-home')[0].checked = false;
            $('#easee-home-installation')[0].checked = false;
            $('#easee-charge')[0].checked = false;
            $('#accessories')[0].checked = true;
            break;
        case 'home-installation':
            $('#easee-home')[0].checked = false;
            $('#easee-home-installation')[0].checked = true;
            $('#easee-charge')[0].checked = false;
            $('#accessories')[0].checked = false;
            break;
        case 'charge':
            $('#easee-home')[0].checked = false;
            $('#easee-home-installation')[0].checked = false;
            $('#easee-charge')[0].checked = true;
            $('#accessories')[0].checked = false;
    }

    updateFilter(false);

    $filterCheckboxes.change(function() {
        updateFilter();
        map.addAccounts(filteredAccounts);
        map.showAccounts();
    });

    $retailerSearchInput.keyup(function() {
        //if ($retailerSearchInput.val().length===0) {
        updateFilter();
        if($retailerSearchInput.val().length===0) {
            if(orderPage) {
                $(".order-guide .order-wrapper").addClass("no-transition");
                setTimeout(() => {
                    const $listTopOffset = $(".retailers").offset().top;
                    const $listHeight = $(".retailers").outerHeight();
                    $(".order-guide .order-wrapper").css("min-height", ($listTopOffset + $listHeight) - 21);
                }, 400);
                setTimeout(() => {
                    $(".order-guide .order-wrapper").removeClass("no-transition");
                }, 1700);
            }
        }
        //}
    });
    if($(".retailers .map-content .map").length === 0) {
        $citySearchInput.keyup(function() {
            updateFilter();
        });
    }
}

function isRestOfWorld() {
    return window.location.pathname.substring(0, 7) === '/world/';
}

function getCountry() {
    let country = localStorage.getItem('country');
    return country === 'null' ? null : country;
}
function updateFilter(noTrans = true) {
    const $mapExpandableHide = $(".retailers .order-near.hide-expandable-selected");
    const $mapExpandableShowPartners = $(".retailers .order-online.hide-expandable-selected-partners-show");

    $filterCheckboxes.each(function(index, el) {
        if((orderPage) && (noTrans !== false)) {
            var options = parseOrderOptions(window.location.search);
            let currentStep = options.step;
            setTimeout(() => {
                const $listTopOffset = $(".retailers").offset().top;
                const $listHeight = $(".retailers").outerHeight();
                if(currentStep === 3) {
                    $(".order-guide .order-wrapper").css("min-height", ($listTopOffset + $listHeight) - 21);
                }
            }, 400);
            $(".order-guide .order-wrapper").addClass("no-transition");
            setTimeout(() => {
                $(".order-guide .order-wrapper").removeClass("no-transition");
            }, 1700);
        }
        if (el.checked && el.value) {
            if(filter.type === el.value) {
                filter.type = el.value;
            } else {         
                filter.type = el.value;

                if(el.value === "home") {
                    $mapExpandableHide.addClass("hide-map");
                    $mapExpandableShowPartners.addClass("show-partners-only");
                } else if(el.value === "accessories") {
                    $mapExpandableHide.addClass("hide-map");
                    $mapExpandableShowPartners.addClass("show-partners-only");
                } else if(el.value === "charge") {
                    $mapExpandableHide.addClass("hide-map");
                    $mapExpandableShowPartners.addClass("show-partners-only");
                } else {
                    $mapExpandableHide.removeClass("hide-map");
                    $mapExpandableShowPartners.removeClass("show-partners-only");
                }
                
                if(!$filterInMap) {
                    if((el.value === "home") || (filter.type === "home") || (el.value === "accessories")) {
                        if((homeInstallationPartners.length === 0) || (chargePartners.length === 0)) {
                            $(".order-online").removeClass("inactive");
                        };
                        $(".order-online .accounts").slideDown();
                        $(".order-online > a").addClass("only-home");
                        setTimeout(() => { $(".order-near").hide() }, 350);
                    } else if($(".accounts").is(":visible")) {
                        if((chargePartners.length === 0) || (homeInstallationPartners.length === 0)) {
                            if((el.value === "home-installation") && (homeInstallationPartners.length === 0)) {
                                $(".order-online").addClass("inactive");
                                $(".order-near > a").addClass("only-home");
                                $(".order-near .map-search-content").show();
                                $(".order-near").show();
                            } else if((el.value === "charge") && (chargePartners.length === 0)) {
                                $(".order-online").addClass("inactive");
                                $(".order-near > a").addClass("only-home");
                                $(".order-near .map-search-content").show();
                                $(".order-near").show();
                            } else {
                                $(".order-online").removeClass("inactive");
                                $(".order-near").show();
                                $(".order-online > a").removeClass("only-home");
                                $(".order-near > a").removeClass("only-home");
                                $(".order-online .accounts").slideUp();
                                $(".order-near .map-search-content").slideUp();
                                $(".order-online > a").removeClass("active");
                                $(".order-near > a").removeClass("active");
                            }
                        } else {
                            $(".order-near").show();
                            $(".order-online > a").removeClass("only-home");
                            $(".order-online .accounts").slideUp();
                            $(".order-near .map-search-content").slideUp();
                            $(".order-online > a").removeClass("active");
                            $(".order-near > a").removeClass("active");
                        }
                        setTimeout(() => { $combinedHeightTop = $(".retailers .map-content .map").offset().top }, 500);
                    } else {
                        if((chargePartners.length === 0) || (homeInstallationPartners.length === 0)) {
                            if((el.value === "home-installation") && (homeInstallationPartners.length === 0)) {
                                $(".order-online").addClass("inactive");
                                $(".order-near > a").addClass("only-home");
                                $(".order-near .map-search-content").show();
                                $(".order-near").show();
                            } else if((el.value === "charge") && (chargePartners.length === 0)) {
                                $(".order-online").addClass("inactive");
                                $(".order-near > a").addClass("only-home");
                                $(".order-near .map-search-content").show();
                                $(".order-near").show();
                            } else {
                                $(".order-online").removeClass("inactive");
                                $(".order-near").show();
                                $(".order-online > a").removeClass("only-home");
                                $(".order-near > a").removeClass("only-home");
                                $(".order-online .accounts").slideUp();
                                $(".order-near .map-search-content").slideUp();
                                $(".order-online > a").removeClass("active");
                                $(".order-near > a").removeClass("active");
                            }
                        } else {
                            $(".order-near").show();
                            $(".order-online > a").removeClass("only-home");
                            $(".order-online .accounts").slideUp();
                            $(".order-near .map-search-content").slideUp();
                            $(".order-online > a").removeClass("active");
                            $(".order-near > a").removeClass("active");
                        }
                        setTimeout(() => { $combinedHeightTop = $(".retailers .map-content .map").offset().top }, 500);
                    }
                }
            }
        }
    });

    filter.search = ($retailerSearchInput.val() || '').toLowerCase();
    filter.searchCode = ($citySearchInput.val() || '').toLowerCase();
    let filterCodeSliced = filter.searchCode.slice(1, 2);
    if(isNaN(filterCodeSliced) === false && filter.searchCode.length > 1) {
        filter.searchCode = filter.searchCode.slice(0, 1)+ ",";
    }

    filteredAccounts = filterAccounts(allRetailers);
    var filteredIds = filterIds(filteredAccounts);

    filteredPartnersOnly = filterAccounts(onlyPartners);
    var filterIdsPartners = filterIds(filteredPartnersOnly, true);

    // When searching, sort alphabetically
    if (filter.search) {

        var sorted = $retailersList.find('.account').sort(function (a, b) {
            var aName = (a.getAttribute('data-name') || '').toLowerCase();
            var bName = (b.getAttribute('data-name') || '').toLowerCase();
            if (aName < bName) return -1;
            if (aName > bName) return 1;
            return 0;
        });
        $retailersList.html(sorted);
        if(orderPage) {
            setTimeout(() => {
                const $listTopOffset = $(".retailers").offset().top;
                const $listHeight = $(".retailers").outerHeight();
                if(window.innerWidth < 1000) {
                    const $listTopOffset = $(".retailers .list").offset().top;
                    const $listHeight = $(".retailers .list").outerHeight();
                    $(".order-guide .order-wrapper").css("min-height", ($listTopOffset + $listHeight))
                } else {
                    $(".order-guide .order-wrapper").css("min-height", ($listTopOffset + $listHeight) - 21)
                }
            }, 400);
        }
    }
    // else, sort by distance + retailer/partner type (sortAccounts function)
    else if($(".retailers .map-content .map").length !== 0) {
        var sortedAccounts = sortAccounts(allRetailers);

        var sorted = $retailersList.find('.account').sort(function (a, b) {
            return sortedAccounts.indexOf(a.getAttribute('data-account-number')) - sortedAccounts.indexOf(b.getAttribute('data-account-number'));
        });
        $retailersList.html(sorted);
    }

    setupAccountClickListeners();

    var visibleAccounts = 0;

    if($(".retailers .map-content .map").length === 0) {
        $retailersList.find('.account').each(function(index, el) {
            if ((filteredIds.indexOf(el.getAttribute('data-account-number')) !== -1 && (filter.search.length === 0 && filter.searchCode.length === 0) || (el.getAttribute('data-name').toLowerCase().indexOf(filter.search) !== -1 && el.getAttribute('data-coverage').toLowerCase().indexOf(filter.searchCode.slice(0, 2)) !== -1))) {
                if(filter.searchCode !== "") {
                    const hitString = el.getAttribute('data-coverage').toLowerCase();
                    const hitIndex = hitString.indexOf(", " + filter.searchCode);
                    const hitIndex2 = hitString.indexOf("," + filter.searchCode);

                    if(filter.searchCode.slice(1,2) === ",") {
                        if((hitIndex !== -1) || (hitIndex2 !== -1)) {
                            $(el).removeClass('hide');
                            visibleAccounts++;
                        } else {
                            $(el).addClass('hide');
                        }
                    } else {
                        $(el).removeClass('hide');
                        visibleAccounts++;
                    }
                } else {
                    $(el).addClass('hide');
                }
            } else if(!($(el).hasClass("Premium") && $(el).hasClass("Partner") && $(el).hasClass("Retailer"))) {
                $(el).addClass('hide');
            }
        });
    } else {
        $retailersList.find('.account').each(function(index, el) {
            if (filteredIds.indexOf(el.getAttribute('data-account-number')) !== -1 && (filter.search.length === 0 || el.getAttribute('data-name').toLowerCase().indexOf(filter.search) !== -1)) {
                $(el).removeClass('hide');
                visibleAccounts++;
            } else if(!($(el).hasClass("Premium") && $(el).hasClass("Partner") && $(el).hasClass("Retailer"))) {
                $(el).addClass('hide');
            }
        });
    }

    if (visibleAccounts === 0 && filter.searchCode.length > 0) {
        $noResults.addClass('show');
    } else {
        $noResults.removeClass('show');
    }

    $retailersListPartners.find('.account').each(function(index, el) {
        if (filterIdsPartners.indexOf(el.getAttribute('data-account-number')) !== -1) {
            $(el).removeClass('hide');
        } else if(!($(el).hasClass("Premium") && $(el).hasClass("Partner") && $(el).hasClass("Retailer"))) {
            $(el).addClass('hide');
        }
    });

    adjustPartnerStyles();

    if($(".retailers .map-content .map").length === 0) {
        adjustPartnerInListStyles();
    }
}

function adjustPartnerStyles() {
    if($('.order-online .account:not(.hide)').length >= 1) {
        $(".retailers .no-visible-accounts").hide();
        $retailersListPartners.find('.account:not(.hide)').each((index,e) => {
            var $e = $(e);
            if(window.innerWidth < 1000) {
                if(index === $retailersListPartners.find('.account:not(.hide)').length - 1) {
                    $e.css({
                        "border-bottom": "none",
                        "padding-right": "0",
                        "padding-left": "0",
                    })
                } else {
                    $e.css({
                        "border-bottom": "1px solid #E6E7E8",
                        "padding-right": "0",
                        "padding-left": "0",
                    })
                }
            } else {
                if(index === $retailersListPartners.find('.account:not(.hide)').length - 1) {
                    if(index % 2 === 0) {
                        $e.css({
                            "border-bottom": "none",
                            "padding-left": "0",
                            "padding-right": "3.981264637%",
                        })
                    } else {
                        $e.css({
                            "border-bottom": "none",
                            "padding-left": "3.981264637%",
                            "padding-right": "0",
                        })
                    }
                } else if(index === $retailersListPartners.find('.account:not(.hide)').length - 2) {
                    if(index % 2 === 0) {
                        $e.css({
                            "border-bottom": "none",
                            "padding-left": "0",
                            "padding-right": "3.981264637%",
                        })
                    } else {
                        $e.css({
                            "border-bottom": "1px solid #E6E7E8",
                            "padding-left": "3.981264637%",
                            "padding-right": "0",
                        })
                    }
                } else {
                    if(index % 2 === 0) {
                        $e.css({
                            "border-bottom": "1px solid #E6E7E8",
                            "padding-left": "0",
                            "padding-right": "3.981264637%",
                        })
                    } else {
                        $e.css({
                            "border-bottom": "1px solid #E6E7E8",
                            "padding-left": "3.981264637%",
                            "padding-right": "0",
                        })
                    }
                }
            }
        })
    } else {
        $(".retailers .no-visible-accounts").show();
    }
}

function adjustPartnerInListStyles() {
    $(".retailers .list .accounts").find('.account:not(.hide)').each((index,e) => {
        var $e = $(e);
        if(window.innerWidth < 1000) {
            if(index === $(".retailers .list .accounts").find('.account:not(.hide)').length - 1) {
                $e.css({
                    "border-bottom": "none",
                    "padding-right": "0",
                    "padding-left": "0",
                })
            } else {
                $e.css({
                    "border-bottom": "1px solid #E6E7E8",
                    "padding-right": "0",
                    "padding-left": "0",
                })
            }
        } else {
            if(index === $(".retailers .list .accounts").find('.account:not(.hide)').length - 1) {
                if(index % 2 === 0) {
                    $e.css({
                        "border-bottom": "none",
                        "padding-left": "0",
                        "padding-right": "3.981264637%",
                    })
                } else {
                    $e.css({
                        "border-bottom": "none",
                        "padding-left": "3.981264637%",
                        "padding-right": "0",
                    })
                }
            } else if(index === $(".retailers .list .accounts").find('.account:not(.hide)').length - 2) {
                if(index % 2 === 0) {
                    $e.css({
                        "border-bottom": "none",
                        "padding-left": "0",
                        "padding-right": "3.981264637%",
                    })
                } else {
                    $e.css({
                        "border-bottom": "1px solid #E6E7E8",
                        "padding-left": "3.981264637%",
                        "padding-right": "0",
                    })
                }
            } else {
                if(index % 2 === 0) {
                    $e.css({
                        "border-bottom": "1px solid #E6E7E8",
                        "padding-left": "0",
                        "padding-right": "3.981264637%",
                    })
                } else {
                    $e.css({
                        "border-bottom": "1px solid #E6E7E8",
                        "padding-left": "3.981264637%",
                        "padding-right": "0",
                    })
                }
            }
        }
    })
}

function sortAccounts(accounts, resetOrder = false) {
    // Do we have a location? Filter by distance (with partner/premium exceptions)
    if (location && filter.type !== 'home' && resetOrder === false) {
        var lat = location.lat;
        var lng = location.lng;

        accounts.sort(function(a, b) {
            if (!a.location && !b.location) {
                return 0;
            }

            if (!a.location) {
                return 1;
            }

            if (!b.location) {
                return -1;
            }

            var latA = a.location.lat;
            var lngA = a.location.lng;
            var latB = b.location.lat;
            var lngB = b.location.lng;

            var distanceA = distance(lat, lng, latA, lngA, 'K');
            var distanceB = distance(lat, lng, latB, lngB, 'K');

            if($orderbyDistance) {
                // Sort everyone based on distance
                return distanceA < distanceB ? -1 : 1;
            } else {
                // Easee Home with installation – national partners
                if (filter.type === 'home-installation') {
                    // Filter national home installation partners at the top
                    if (nationalHomeInstallationPartners.indexOf(a.accountNumber) !== -1 && nationalHomeInstallationPartners.indexOf(b.accountNumber) !== -1) {
                        return nationalHomeInstallationPartners.indexOf(a.accountNumber) - nationalHomeInstallationPartners.indexOf(b.accountNumber);
                    }

                    if (nationalHomeInstallationPartners.indexOf(a.accountNumber) !== -1) {

                        return -1;
                    }

                    if (nationalHomeInstallationPartners.indexOf(b.accountNumber) !== -1) {
                        return 1;
                    }
                }

                // Easee Charge – national partners
                if (filter.type === 'charge') {
                    // Filter national Charge partners at the top
                    if (nationalChargePartners.indexOf(a.accountNumber) !== -1) {
                        return -1;
                    }

                    if (nationalChargePartners.indexOf(b.accountNumber) !== -1) {
                        return 1;
                    }
                }

                // Sort partners within distance limit at the top
                if (a.type === 'Partner' && b.type !== 'Partner' && distanceA < distanceLimit) {
                    return -1;
                } else if (a.type !== 'Partner' && b.type === 'Partner' && distanceB < distanceLimit) {
                    return 1;
                }

                // Sort Premium partners with distance limit next
                if (a.type === 'Premium Retailer' && b.type !== 'Premium Retailer' && distanceA < distanceLimit) {
                    return -1;
                } else if (b.type === 'Premium Retailer' && a.type !== 'Premium Retailer' && distanceB < distanceLimit) {
                    return 1;
                }

                // Sort everyone else based on distance
                return distanceA < distanceB ? -1 : 1;
            }
        });
    } else {
        // No location found, sort by other parameters
        accounts.sort(function (a, b) {
            if($orderbyDistance) {
                // Sort everyone by name
                return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
            } else {
                // Sort partners 
                if (a.type === 'Partner' && b.type !== 'Partner') {
                    return -1;
                } else if (a.type !== 'Partner' && b.type === 'Partner') {
                    return 1;
                }
                // Sort premium retailers after partner
                if (a.type === 'Premium Retailer' && b.type !== 'Premium Retailer') {
                    return -1;
                } else if (a.type !== 'Premium Retailer' && b.type === 'Premium Retailer') {
                    return 1;
                }

                // Sort partners withim themselves
                if (a.type === 'Partner' && b.type === 'Partner') {
                    if (filter.type === 'home') {
                        return homePartners.indexOf(a.accountNumber) < homePartners.indexOf(b.accountNumber) ? -1 : 1;
                    } else if (filter.type === 'home-installation') {
                        // Sort home installation partners according to position in list
                        var aIndex = homeInstallationPartners.indexOf(a.accountNumber);
                        var bIndex = homeInstallationPartners.indexOf(b.accountNumber);
                        if (aIndex !== -1 && bIndex === -1) {
                            return -1;
                        } else if (aIndex === -1 && bIndex !== -1) {
                            return 1;
                        } else if (aIndex === -1 && bIndex === -1) {
                            return a.name.toLowerCase() < b.name.toLowerCase();
                        }

                        return aIndex - bIndex;
                    }
                }

                // Sort everyone else by name
                return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
            }
        });
    }

    return accounts.map(account => account.accountNumber);
}

function filterAccounts(accounts, partnersOnly = "false") {
    if(partnersOnly === false) {
        var searchString = filter.search.toLowerCase();
    } else {
        var searchString = "";
    }

    var country = getCountry();
    var _isRestOfWorld = isRestOfWorld();
    var salesforceCountries = _isRestOfWorld && country ? ((window.countryMapping[country]?.salesforceCountryNames || '').split(',')) : null;

    function filterSearch(retailer) {
        var isCorrectRetailerCountry = true;
        if (salesforceCountries) {
            var retailerCountry = retailer.country;
            if (retailerCountry && salesforceCountries.indexOf(retailerCountry) === -1) {
                isCorrectRetailerCountry = false;
            }
        }
        return !(retailer.type === 'Partner' && searchString.length > 0) && isCorrectRetailerCountry;
    }

    // If on page without labels, filter all retailers
    const $isMapListOnly = $(".retailers.mapListViewOnly");
    if($isMapListOnly.length > 0) {
        const country = $isMapListOnly.attr("data-salesforce-country");
        return accounts.filter(retailer => country.indexOf(retailer.country) !== -1  && filterSearch(retailer));
    } else { // else filter partners
        switch (filter.type) {
            case 'home':
                return accounts.filter(retailer => homePartners.indexOf(retailer.accountNumber) !== -1 && filterSearch(retailer));
            case 'home-installation':
                return accounts.filter(retailer => (retailer.type !== 'Partner' || homeInstallationPartners.indexOf(retailer.accountNumber) !== -1 || nationalHomeInstallationPartners.indexOf(retailer.accountNumber) !== -1) && filterSearch(retailer));
            case 'charge':
                return accounts.filter(retailer => (retailer.type !== 'Partner' || chargePartners.indexOf(retailer.accountNumber) !== -1 || nationalChargePartners.indexOf(retailer.accountNumber) !== -1) && filterSearch(retailer));
            case 'accessories':
                return accounts.filter(retailer => accessoryPartners.indexOf(retailer.accountNumber) !== -1 && filterSearch(retailer));
            default:
                return true;
        }
    }
}

function filterIds(filteredAccounts) {
    return filteredAccounts.map(account => account.accountNumber);
}

function setupAccountClickListeners() {
    $retailersList.find('.account').click(function(e) {
        var $target = $(e.target);

        $target = $target.hasClass('account') ? $target : $target.parents('.account');
        var accountNumber = $target.data('accountNumber');

        if($orderbyDistance) {
            map.openAccountInfo(accountNumber);
        } else {
            if ($target.data('type') === 'Partner') {
                return;
            }
            map.openAccountInfo(accountNumber);
        }
    });
}

function initRetailersMap() {
    if($(".retailers .map-content .map").length > 0) {
        var countryInfo = isRestOfWorld() && getCountry() ? window.countryMapping[getCountry()] : null;

        var center = {
            lat: parseFloat(countryInfo?.lat || window.retailerMapOptions.location.lat),
            lng: parseFloat(countryInfo?.lng || window.retailerMapOptions.location.lng)
        };
        var zoom = location ? 10 : parseFloat(countryInfo?.zoom || window.retailerMapOptions.zoom);

        // Show whole Europe if we are on the '/world' site with no country selected
        if (window.location.href.indexOf('/world') !== -1) {
            if (countryInfo) {
                $('.retailers .list .no-results').html('Looks like we are still working our way towards your region. See you soon!');
            } else {
                center = {
                    lat: 56.9918279,
                    lng: 9.288249
                };
                zoom = 4
            }
        }

        var options = {
            zoom: zoom,
            zoomControlOptions: {
                position: google.maps.ControlPosition.RIGHT_TOP
            },
            fullscreenControl: false,
            mapTypeControl: false,
            streetViewControl: false,
            center: location || center,
            styles: [
                { elementType: 'geometry', stylers: [{ color: '#F2F2F2' }] },
                { elementType: 'labels.text.stroke', stylers: [{ color: '#F2F2F2' }] },
                { elementType: 'labels.text.fill', stylers: [{ color: '#F2F2F2' }] },
                {
                    featureType: 'administrative.locality',
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#424242' }]
                },
                {
                    featureType: 'poi',
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#424242' }]
                },
                {
                    featureType: 'poi.park',
                    elementType: 'geometry',
                    stylers: [{ color: '#F2F2F2' }]
                },
                {
                    featureType: 'poi.park',
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#6b9a76' }]
                },
                {
                    featureType: 'road',
                    elementType: 'geometry',
                    stylers: [{ color: '#FFFFFF' }]
                },
                {
                    featureType: 'road',
                    elementType: 'geometry.stroke',
                    stylers: [{ color: '#FFFFFF' }]
                },
                {
                    featureType: 'road',
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#FFFFFF' }]
                },
                {
                    featureType: 'road.highway',
                    elementType: 'geometry',
                    stylers: [{ color: '#FFFFFF' }]
                },
                {
                    featureType: 'road.highway',
                    elementType: 'geometry.stroke',
                    stylers: [{ color: '#FFFFFF' }]
                },
                // {
                //   featureType: 'road.highway',
                //   elementType: 'labels',
                //   stylers: [{ color: '#B3B3B3' }]
                // },
                {
                    featureType: 'road.highway',
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#FFFFFF' }]
                },

                {
                    featureType: 'transit',
                    elementType: 'geometry',
                    stylers: [{ color: '#F9F9F9' }]
                },
                {
                    featureType: 'transit.station',
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#d59563' }]
                },
                {
                    featureType: 'water',
                    elementType: 'geometry',
                    stylers: [{ color: '#C0E5F4' }]
                },
                {
                    featureType: 'water',
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#515c6d' }]
                },
                {
                    featureType: 'water',
                    elementType: 'labels.text.stroke',
                    stylers: [{ color: '#C0E5F4' }]
                }
            ]
        };

        map = new EaseeMap();
        map.setupMap('retailer-map', options);
        map.addAccounts(filteredAccounts);
        map.showAccounts();

        map.markerCallback = function(account) {
            var accountNumber = account.accountNumber;
            var $el = $('[data-account-number="' + accountNumber + '"]');
            if ($el.length > 0) {
                var elementOffsetTop = $el.offset().top;
                var $retailersList = $('.retailers .list');
                var listScrollTop = $retailersList.scrollTop();
                var listOffsetTop = $retailersList.offset().top;

                var scrollTop = elementOffsetTop - listOffsetTop + listScrollTop;

                $retailersList.animate({
                    scrollTop: scrollTop
                }, 500);
            }
        };

        function setupAutocomplete() {
            var options = window.googlePlacesCountryCode ? {
                componentRestrictions: {country: window.googlePlacesCountryCode }
            } : {};
            var autocomplete = new google.maps.places.Autocomplete($citySearchInput.get(0), options);
            autocomplete.addListener('place_changed', function() {
                var place = autocomplete.getPlace();
                if (place.geometry) {
                    var lat = place.geometry.location.lat();
                    var lng = place.geometry.location.lng();
                    var position = {
                        coords: {
                            latitude: lat,
                            longitude: lng
                        }
                    }
                    geoSuccess(position);
                } else {
                    updateFilter();
                }
            })
            return autocomplete;
        }

        if($(".retailers .map-content .map").length > 0) {
            setupAutocomplete();
        }

        return;
    }
}

window.initRetailersMap = initRetailersMap;


//:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
//:::                                                                         :::
//:::  This routine calculates the distance between two points (given the     :::
//:::  latitude/longitude of those points). It is being used to calculate     :::
//:::  the distance between two locations using GeoDataSource (TM) prodducts  :::
//:::                                                                         :::
//:::  Definitions:                                                           :::
//:::    South latitudes are negative, east longitudes are positive           :::
//:::                                                                         :::
//:::  Passed to function:                                                    :::
//:::    lat1, lon1 = Latitude and Longitude of point 1 (in decimal degrees)  :::
//:::    lat2, lon2 = Latitude and Longitude of point 2 (in decimal degrees)  :::
//:::    unit = the unit you desire for results                               :::
//:::           where: 'M' is statute miles (default)                         :::
//:::                  'K' is kilometers                                      :::
//:::                  'N' is nautical miles                                  :::
//:::                                                                         :::
//:::  Worldwide cities and other features databases with latitude longitude  :::
//:::  are available at https://www.geodatasource.com                         :::
//:::                                                                         :::
//:::  For enquiries, please contact sales@geodatasource.com                  :::
//:::                                                                         :::
//:::  Official Web site: https://www.geodatasource.com                       :::
//:::                                                                         :::
//:::               GeoDataSource.com (C) All Rights Reserved 2018            :::
//:::                                                                         :::
//:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

function distance(lat1, lon1, lat2, lon2, unit) {
    if ((lat1 === lat2) && (lon1 === lon2)) {
        return 0;
    }
    else {
        var radlat1 = Math.PI * lat1/180;
        var radlat2 = Math.PI * lat2/180;
        var theta = lon1-lon2;
        var radtheta = Math.PI * theta/180;
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        dist = dist * 180/Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit==="K") { dist = dist * 1.609344 }
        if (unit==="N") { dist = dist * 0.8684 }
        return dist;
    }
}

if($(".retailers .order-near > a.only-home").length > 0) {
    $(".retailers .order-near > a").toggleClass("active");
    if($(".retailers .order-near > a").hasClass("active")) {
        $(".order-near .map-search-content.hide-map").show();
        $(".order-near .map-search-content").slideDown();
        $combinedHeightTop = $(".retailers .map-content").offset().top;
        const $retailerMap = $(".retailers .map-content .map");
        if(window.innerWidth < 1000) {
            if(orderPage) {
                $retailerMap.css({
                    "position": "fixed",
                    "top": "85vh",
                    "bottom": "auto"
                });
            } else {
                $retailerMap.css({
                    "position": "fixed",
                    "top": "25vh",
                    "bottom": "auto"
                });
            }
        } else {
            $retailerMap.css({
                "position": "absolute",
                "top": "0",
                "bottom": "auto"
            });
        }
    } else {
        $(".order-near .map-search-content").slideUp();
    }
    setTimeout(() => { $combinedHeightTop = $(".retailers .map-content").offset().top }, 700);
}