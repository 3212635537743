const locationMarkerIcon = '<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve"><style type="text/css"> .st0{fill:#157EFF;} .st1{fill:#FFFFFF;} </style><g><circle class="st0" cx="12" cy="12" r="10.2"/><path class="st1" d="M12,3.5c4.7,0,8.5,3.8,8.5,8.5s-3.8,8.5-8.5,8.5S3.5,16.7,3.5,12S7.3,3.5,12,3.5 M12,0C5.4,0,0,5.4,0,12 s5.4,12,12,12s12-5.4,12-12S18.6,0,12,0L12,0z"/></g></svg>';
const premiumRetailerMarkerIcon = '<svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g transform="translate(-1057.000000, -1095.000000)" fill="#005AFF"><circle id="Oval-Copy-20" cx="1065" cy="1103" r="8"></circle></g></g></svg>';
const retailerMarkerIcon = '<svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g transform="translate(-1057.000000, -1095.000000)" fill="#768692"><circle id="Oval-Copy-20" cx="1065" cy="1103" r="8"></circle></g></g></svg>';
const $orderbyDistance = $(".retailers.mapListViewOnly").length > 0 ? true : false;

const mapIcons = {
    locationMarkerIcon
};

class EaseeMap {
    accounts = [];
    filteredAccounts = [];
    map = null;
    currentLocationMarker = null;
    location = null;
    stavangerCoords = {lat: 58.970558, lng: 5.730233};
    defaultZoom = 15;
    infoWindow = null;
    markers = [];
    markerCallback = null;
    visibleAccountsCallback = null;

    constructor() {

    }

    isDevEnvironment() {
        return window.location.hostname.includes(".local") || window.location.hostname.includes(".test");
    }

    setMarkerCallback(callback) {
        this.markerCallback = callback;
    }

    setVisibleAccountsCallback(callback) {
        this.visibleAccountsCallback = callback;
    }

    setupMap(id, options) {
        this.map = new google.maps.Map(document.getElementById(id), options);
        this.setupInfoWindow();
        //this.getLocation();
        let self = this;
        this.map.addListener('bounds_changed', function() {
            if (self.visibleAccountsCallback!==null)
                self.visibleAccountsCallback(self.getVisibleMapAccounts());
        });
    }

    getDistanceBetweenLocAndYou(loc) {
        if (location === null) return;

        let locYou = new google.maps.LatLng(this.location.coords.latitude, this.location.coords.longitude);

        let len = google.maps.geometry.spherical.computeDistanceBetween(
            new google.maps.LatLng(loc.lat, loc.lng),
            locYou);

        return len;
     }


    setupInfoWindow() {
        this.infoWindow = new google.maps.InfoWindow();

        this.map.data.addListener('click', function (event) {
            //showFeature(event.feature, event.latLng);
        });
    }


    setupMarkerInfoWindows(marker, title, description) {
        let self = this;
        
        marker.addListener('click', function () {
            let content = '<div class="info-window">' +
                '<h3>' + title + '</h3>' +
                '<div class="info">' + description + '</div>' +
                '</div>';

            self.infoWindow.setContent(content);
            self.infoWindow.setOptions({pixelOffset: new google.maps.Size(0, -10)});
            self.infoWindow.open(self.map, marker);
            
            if (self.markerCallback!==null && self.markerCallback!==undefined) self.markerCallback(self.accounts.find(account => account.accountNumber===marker.mapInfo.accountNumber))
        })
    }

    addMarker(account) {
        if (account.location===null) return;
        if (!account.webpage && account.type === "Retailer") return;

        const icon = ['Premium Retailer', 'Partner'].indexOf(account.type) !== -1 ? $orderbyDistance ? retailerMarkerIcon : premiumRetailerMarkerIcon : retailerMarkerIcon;
        const url = 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(icon);
        const markerIcon = {
            url: url
        };

        let marker = new google.maps.Marker({
            position: account.location,
            map: this.map,
            mapInfo: {
                accountNumber: account.accountNumber
            },
            icon: markerIcon
        });

        let description = '';
        if (account.street || account.city) {
            description += '<address>';
            if (account.street) {
                description += account.street + '<br>';
            }

            if (account.postalCode) {
                description += account.postalCode + ' ';
            }

            if (account.city) {
                description += account.city + '<br>';
            }

            if (account.country) {
                description += account.country;
            }

            description += '</address>';
        }

        if (account.phone || account.email || account.webpage) {

            description += '<div class="contact">';

            /* if (account.phone) {
                description += '<p>T: <a href="tel:' + this.stripWhitespace(account.phone) + '">' + account.phone + '</a></p>';
            }

            if (account.email) {
                description += '<p>E: <a href="email:' + account.email + '">' + account.email + '</a></p>';
            } */

            if (account.webpage) {
                description += '<p><a href="' + this.prependMissingProtocol(account.webpage) + '" target="_blank">' + account.webpage + '</a></p>';
            }

            description += '</div>'
        }

        this.setupMarkerInfoWindows(marker, account.name, description);
        this.markers.push(marker);
    }

    prependMissingProtocol(url) {
        if (url && url.length >= 4 && url.substring(0, 4) !== 'http') {
            return 'http://' + url;
        }

        return url;
    }

    stripWhitespace(input) {
        return input ? input.replace(/\s/g, '') : '';
    }

    getLocation() {
        if (this.isDevEnvironment()) {
            let location1 = {
                coords: {
                    latitude: this.stavangerCoords.lat,
                    longitude: this.stavangerCoords.lng
                }
            };

            let location2 = {
                coords: {
                    latitude: 58.966427,
                    longitude: 5.73302
                }
            };


            this.showPosition(location1);

            setInterval(function() {
                if (this.location && this.location.coords) {
                    if (this.location.coords.latitude === location1.coords.latitude) {
                        this.showPosition(location2);
                    } else {
                        this.showPosition(location1);
                    }
                }
            }, 8000);
        }

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.showPosition);
            navigator.geolocation.watchPosition(this.showPosition);
        } else {
            console.log("Geolocation not supported");
        }
    }

    showPosition(position) {
        this.location = position;
        this.showLocationMarker();
    }

    showLocationMarker() {
        if (!this.location) {
            return;
        }

        let position = new google.maps.LatLng(this.location.coords.latitude, this.location.coords.longitude);
        let url = 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(mapIcons.locationMarkerIcon);

        let icon = {
            url: url,
            scaledSize: new google.maps.Size(20, 20), // scaled size
            optimized: false,
            //anchor: anchor
        };

        if (this.currentLocationMarker) {
            this.currentLocationMarker.setPosition(position);
        } else {
            this.currentLocationMarker = new google.maps.Marker({
                position: position,
                map: this.map,
                icon: icon ? icon : null
            });
        }
    }

    getVisibleMapMarkers() {
        return this.markers.filter(marker => this.map.getBounds().contains(marker.getPosition()));
    }

    removeMarkers() {
        this.markers.forEach(function (marker) {
            marker.setMap(null);
        });
        this.markers = [];
    }

    addAccounts(accounts) {
        this.accounts = accounts ? accounts.map(a => Object.assign({}, a)) : null;
        this.filteredAccounts = this.accounts.filter(account => true);
    }

    showAccounts() {
        this.removeMarkers();
        this.filteredAccounts.map(account => {
            this.addMarker(account)
        });
    }

    getDistanceBetweenAccountAndYou(account) {
        if (account.location===null) return;

        return this.getDistanceBetweenLocAndYou(account.location);
    }

    getVisibleMapAccounts() {
        let visibleMarkers = this.getVisibleMapMarkers();
        return visibleMarkers.map(marker => this.accounts.find(account => account.accountNumber===marker.accountNumber));
    }

    openAccountInfo(accountNumber) {
        const accountNumberString = "" + accountNumber;
        const marker = this.markers.find(marker => marker.mapInfo.accountNumber == accountNumberString);
        new google.maps.event.trigger( marker, 'click' );
    }


}

function mapLoaded() {
    let m = new EaseeMap();
    m.setupMap('map');
/*    m.addAccounts(accounts);
    m.showAccounts();

    m.setMarkerCallback((account) => {
        console.log("Got", account);
    })

    m.setVisibleAccountsCallback((accounts) => {
        console.log("Accounts", accounts);
    })
 */
}

export default EaseeMap;
